import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Container, Box } from "theme-ui";
import SEO from '../components/seo';
import PrivacyHero from "../components/PrivacyHero";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Privacy Policy" description="" mdxType="SEO" />
    <PrivacyHero mdxType="PrivacyHero" />
    <Box sx={{
      pt: [4, 5, 5],
      pb: [4, 5, 5],
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
We take your privacy seriously. This document tells you how we will
process and protect any data you share with us. Our data protection policy
is based on the following principles:
      <ul>
        <li parentName="ul">{`We recognise the need for user privacy and data protection legislation compliance`}</li>
        <li parentName="ul">{`We understand we have a duty of care to our clients`}</li>
        <li parentName="ul">{`We only collect and process data that is absolutely necessary and in the best interests of our clients and any data collected will be for the purpose of the services we provide`}</li>
        <li parentName="ul">{`We will never pass any client data to a third party unless we have your express permission and it is in your best interest`}</li>
        <li parentName="ul">{`We endeavour to ensure that all our data is correct and up to date`}</li>
        <li parentName="ul">{`We aim to comply with UK Data Protection Act 1988 (DPA)`}</li>
        <li parentName="ul">{`EU Data Protection Directive 1995 (DPD)`}</li>
        <li parentName="ul">{`EU General Data Protection Regulation 2018 (GDPR)`}</li>
      </ul>
      <h4>{`Contact forms and emails`}</h4>
      <p>{`If you contact us using any of the forms on our web site, the information you provide will be transferred to us via email. It will be stored in our database and will be added to our email newsletter list from which you can unsubscribe at any time. A link to do so will be at the foot of every newsletter. Alternatively, you can contact us directly to request to be removed from our email list.`}</p>
      <h4>{`Site visitor tracking`}</h4>
      <p>{`This site uses Analytics software to track user interaction. This information allows us to determine the number of people using our site, so we can understand how our site is being used, so we can improve our services. We do not have access to information which will identify you.`}</p>
      <p>{`You can prevent the Analytics software from tracking your interaction with the site by disabling cookies on your internet browser.`}</p>
      <h4>{`Data Breaches`}</h4>
      <p>{`In line with data protection regulations, we are obliged to report any data breaches. We will adhere to the requirement to do so within 72 hours and will report to the appropriate authorities. This also applies to any data theft.`}</p>
      <h4>{`Data Controller`}</h4>
      <p>{`The data controller of this website is: Thinkanew Media Ltd.`}</p>
      <p>{`Contact us through our `}<a parentName="p" {...{
          "href": "/contact-us"
        }}>{`contact form`}</a></p>
      <p>{`Our privacy policy may change from time to time in line with legislation or industry developments. Any such changes will be detailed on our website.`}</p>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      